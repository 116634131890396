<template>
  <div class="img-similar">
    <Viewheader :isHome="false" ref="navheader" />
    <!-- banner图 -->
    <BannerHead :title="bigTitle_1" :desc="smallTitle_1">
      <div class="header-custom" slot="content">
        <img class="bg-left" src="@/assets/img/imgSimilar/bg-left.png" />
        <img class="bg-right" src="@/assets/img/imgSimilar/bg-right.png" />
      </div>
    </BannerHead>

    <!-- 核心功能 -->
    <div class="core-function">
      <CommonTitle title="核心功能" />
      <ColumuTwo class="core-function_main" :list="spanList">
        <div slot="img" class="core-img">
          <img src="@/assets/img/imgSimilar/core-1.png" alt="" />
          <div class="core-res">
            <img src="@/assets/img/imgSimilar/core-2.png" alt="" />
            <img src="@/assets/img/imgSimilar/core-3.png" alt="" />
          </div>
        </div>
      </ColumuTwo>
    </div>
    <!-- 能力展示 -->
    <div class="similar-main-wrap">
      <div class="similar-main">
        <CommonTitle title="能力展示" :desc="ability.desc" />
        <div class="operationWidth similar-main-cont">
          <div class="operationBackgroundColor">
            <ImgUpload
              class="sc-img-upload"
              @success="getResultImg"
              @select="selectPicture"
              @change="handleChange"
              :modelIndex.sync="imgCurrentActive"
              :imgList="exampleDiagram"
              :action="action"
              :directionRow1280="true"
              accept="image/jpeg,image/jpg,image/png"
              uploadText="选择一张"
            />
            <div class="operationRight">
              <div v-if="successImg" class="similarityGraphMain">
                <div
                  class="similarityGraph"
                  v-for="(itemImg, indexImg) in beSimilar"
                  :key="indexImg"
                >
                  <div class="similarityGraphImg">
                    <img :src="itemImg.url" />
                  </div>
                  <div class="similarityGraphTitle">
                    {{ itemImg.text }}
                  </div>
                  <div class="similarityGraphSimilarity">
                    相似度 {{ Number(itemImg.score * 100).toFixed(2) + '%' }}
                  </div>
                </div>
              </div>

              <!-- 报错图片 -->
              <div class="errMain" v-if="errImg">
                <div class="errImg">
                  <img src="../../assets/img/err.png" />
                </div>
                <div class="errText">
                  {{ errText }}
                </div>
              </div>

              <!-- 加载特效 -->
              <div class="loadSpecialEffects" v-if="loadImg"></div>
              <!-- 扫描脚框 -->
              <div class="top" v-if="loadImg"></div>
              <div class="bottom" v-if="loadImg"></div>
            </div>
          </div>

          <div class="actionText">
            <div class="detectionText">
              <input
                type="text"
                placeholder="粘贴网络图片URL"
                v-model="urlAdress"
              />
              <button
                class="detectionButton"
                @click="figureSimilarity(1)"
                :disabled="this.urlAdress == ''"
              >
                检测
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 差异优势 -->
    <div class="different-advantage-wrap">
      <div class="different-advantage">
        <CommonTitle title="差异优势" theme="light" />
        <ColumuTwo
          class="different-advantage_main"
          theme="light"
          :list="diffList"
          :img="diffImg"
        ></ColumuTwo>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="application-scene-wrap">
      <div class="application-scene">
        <CommonTitle title="应用场景" />
        <ColumuTwo class="application-scene_main" :img="appImg" :reverse="true">
          <div slot="text">
            <div class="app-title">
              {{ appliList.title }}
              <img src="@/assets/img/imgSimilar/scene-2.png" alt="" />
            </div>
            <ul class="app-list">
              <li v-for="(item, index) in appliList.desc" :key="index">
                <img src="@/assets/img/imgSimilar/scene-3.png" alt="" />
                {{ item }}
              </li>
            </ul>
          </div>
        </ColumuTwo>
      </div>
    </div>

    <!-- 尾部 -->
    <Footering />
  </div>
</template>

<script>
// banner
import BannerHead from './components/Banner.vue';
import CommonTitle from './components/CommonTitle.vue';
import ColumuTwo from './components/ColumuTwo.vue';
// 上传图片列表组件
import ImgUpload from '@/components/secondPage/imgUpload.vue';

import cha5 from '@/assets/img/imgSimilar/different.png';
import app5 from '@/assets/img/imgSimilar/scene.png';
import googleLogo from '@/assets/img/imgSimilar/google.png';

import { figureSimilarity } from '@/api/aidata.js';
import { apiUri } from '../../api/txt';

// 公用组件
import '../../assets/css/public.css';
import '../../assets/css/normalize.css';
import '../../assets/css/imgPublic.css';
import '../../assets/css/commonModule.css';
// 头部
import Viewheader from '../../components/viewheader/viewheader.vue';
// foote组件
import Footering from '@/components/foot/footer.vue';
export default {
  components: {
    BannerHead,
    CommonTitle,
    ColumuTwo,
    ImgUpload,
    Viewheader,
    Footering,
  },
  data() {
    return {
      bigTitle_1:
        '<span style="color:#6C38E0">图像</span><span style="color:#fff">搜索</span>',
      smallTitle_1:
        '<span style="color:#6C38E0">检测图片主体信息，在自</span><span style="color:#fff">有商品库中寻找相似商品</span>',

      // 核心功能
      spanList: [
        {
          title: '商品主体检测',
          desc: '检测图像中的商品主体，并给出主体坐标位置；避免背景等无关信息干扰。',
        },
        {
          title: '自建库图像相似',
          desc: '领域数据集自建库，支持输入图像与自建库文本逐一对比相似度。',
        },
      ],

      //   差异优势
      diffImg: cha5,
      diffList: [
        {
          title: '公有商品库',
          desc: '提供丰富的公有商品库，供查询热销品商品信息。',
        },
        {
          title: '支持千万级大图库',
          desc: '自建图库支持千万级图片量上传入库，实现实时检索，单图毫秒级响应。',
        },
        {
          title: '算法准确性高',
          desc: '基于大量电商训练数据，使用精准的算法迭代模型不断提高准确度。',
        },
        {
          title: '服务简单易用',
          desc: '标准化接口封装，提供丰富的HTTP SDK，接入简单，快速上手。',
        },
      ],
      // 能力展示
      ability: {
        desc: `模型结果对<img src='${googleLogo}' /> <span style='color:#6C38E0'>google 系列产品（shopping/⾕歌seo等）</span>，进⾏了针对性优化，在此场景下使⽤效果更优。模型⽬前仅⽀持png、jpg、jpeg。`,
      },

      //   应用场景
      appImg: app5,
      appliList: {
        title: '图相似对比提升服务能力',
        desc: [
          '创建自身领域内图库，进行拍照购物、相似商品推荐、相似/同款商品比价及竞争力分析。',
          '基于自建库的已有信息，进行“商品打标”、黑样本库商品审核、$符邮费链接等商品治理。',
          '商品上架自动匹配类目、错放类目治理、商品理解（向量化服务）等；视频与商品的关联。',
        ],
      },

      // 在线处理------------------------
      action: `${apiUri}/vector/imgSimilaritySearch`,
      img_url: '',
      result_url: '',
      id: '',
      headers: {
        token: localStorage.getItem('tokenKey')
          ? localStorage.getItem('tokenKey')
          : '',
        isSample: 1,
      },
      // 示例图列表
      exampleDiagram: [
        {
          img: 'https://www.dhresource.com/webp/m/0x0/f2/albu/g10/M00/D5/FF/rBVaWVwUnqCAJr_NAAHFqU5wLw4214.jpg',
        },
        {
          img: 'https://www.dhresource.com/webp/m/0x0/f2/albu/g8/M01/0D/EF/rBVaV1xFaFmAbxCyAAX4QfBOdTc452.jpg',
        },
        {
          img: 'https://img4.dhresource.com/webp/m/0x0/f3/albu/km/h/30/ae03bfe9-2199-469f-920e-03401d165a7a.jpg',
        },
        {
          img: 'https://www.dhresource.com/webp/m/0x0/f2/albu/g19/M01/00/15/rBNaN2GGKO2AbuwFAAa0Tfe1Zvg440.jpg',
        },
        {
          img: 'https://www.dhresource.com/webp/m/0x0/f2/albu/g18/M01/5A/3B/rBVapWDxJAeABJdGAAIaMaNrTi0613.jpg',
        },
      ],
      // 初始化选中状态
      imgCurrentActive: 0,
      // 初始化选中图
      resultGraphImg:
        'https://www.dhresource.com/webp/m/0x0/f2/albu/g10/M00/D5/FF/rBVaWVwUnqCAJr_NAAHFqU5wLw4214.jpg',
      // 成功图
      successImg: false,
      // 失败图
      errImg: true,
      // 正在加载
      loadImg: true,
      // 加载文案
      errText: '处理中....',
      urlAdress: '',
      // 加载时，禁止点击
      prohibitClicking: false,
      // 优化后按钮
      afterOptimization: false,
      beSimilar: [],
      isSample: '',
    };
  },
  computed: {},
  mounted() {
    // 默认点击高亮
    this.selectPicture(this.imgCurrentActive, { img: this.resultGraphImg });
  },
  methods: {
    // 点击选中高亮
    selectPicture(index, { img }) {
      this.resultGraphImg = img;
      this.imgCurrentActive = index;
      this.figureSimilarity(0, img);
    },
    // 图像搜索url  请求
    figureSimilarity(status, resultGraphImg) {
      // if (status == 1) {
      //   this.$message.info('暂不支持自定义上传');
      //   return;
      // }
      // 成功图
      this.successImg = false;
      // 失败图
      this.errImg = true;
      // 正在加载
      this.loadImg = true;
      this.errText = '处理中....';

      if (status == 0) {
        // 点击图片获取地址
        var imgUrl = resultGraphImg;
        // 加载时禁止点击切换
        this.prohibitClicking = true;
        this.isSample = 0;
      } else {
        // 点击检测按钮  获取地址
        var imgUrl = this.urlAdress;
        this.imgCurrentActive = -1;
        this.isSample = 1;
      }

      // 请求接口-------------------
      figureSimilarity({
        imgUrl: imgUrl,
        isSample: this.isSample,
      }).then(({ data }) => {
        if (data.state === '0x0000') {
          // setTimeout(() => {
          this.urlAdress = '';
          this.beSimilar = data.data[0];
          // 成功图
          this.successImg = true;
          // 失败图
          this.errImg = false;
          // 正在加载
          this.loadImg = false;
          // 加载完成  可点击切换
          this.prohibitClicking = false;
          // }, 1000);
        } else {
          this.$message.error(data.message);
          if (data.state === '0x0008') {
            this.$refs.navheader.logOn();
            // }else if(data.state === "00001"){
          } else {
            // setTimeout(() => {
            this.urlAdress = '';
            // 成功图
            this.successImg = false;
            // 失败图
            this.errImg = true;
            this.errText = data.message;
            this.loadImg = false;
            // 加载完成  可点击切换
            this.prohibitClicking = false;
            // }, 0)
          }
        }

        // todo
        this.errImg = false;
        this.successImg = true;
      });
    },
    // 图片change操作
    handleChange(file, fileList) {
      // return;
      // 成功图
      this.successImg = false;
      // 失败图
      this.errImg = true;
      // 正在加载
      this.loadImg = true;
      this.errText = '处理中....';
      this.imgCurrentActive = -1;
      // 加载时禁止点击切换
      this.prohibitClicking = true;
    },
    // 图片上传成功后的回调
    getResultImg(response, file, fileList) {
      if (response.state === '0x0000') {
        setTimeout(() => {
          this.beSimilar = response.data[0];
          // // 成功图
          this.successImg = true;
          // 失败图
          this.errImg = false;
          // 正在加载
          this.loadImg = false;
          // 加载完成  可点击切换
          this.prohibitClicking = false;
        }, 1000);
      } else if (response.state === '0x0008') {
        this.$message.error(response.message);
        this.$refs.navheader.logOn();
      } else {
        setTimeout(() => {
          // 成功图
          this.successImg = false;
          // 失败图
          this.errImg = true;
          this.errText = response.message;
          this.loadImg = false;
          // 加载完成  可点击切换
          this.prohibitClicking = false;
        }, 1000);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.img-similar {
  .banner-header {
    background: linear-gradient(90deg, #faf8ff 0% 50%, #6c38e0 50% 100%);
  }
}
// banner
.header-custom {
  // position: relative;
  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    background-color: rgba(108, 56, 224, 1);
  }
  img {
    position: absolute;
  }
  .bg-left {
    right: 50%;
    bottom: 5%;
    // transform: translateX(-50%);
    width: 50%;
    max-width: 640px;
  }
  .bg-right {
    left: 50%;
    bottom: 5%;
    // transform: translateX(-50%);
    max-width: 640px;
    width: 50%;
  }
}
/* 核心 */
.core-function {
  padding: 60px 0 0;
  .common-two-column_img {
    margin-right: 58px;
    width: 61.8%;
    margin-top: -26px;
    overflow: auto;
  }
  .common-two-column {
    column-gap: 29px;
  }
  .core-img {
    // position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    right: 0;
    > img {
      width: 38.3%;
      position: relative;
      // position: absolute;
      // left: 50%;
      // transform: translateX(-55%);
    }
    .core-res {
      width: 52%;
      display: flex;
      flex-direction: column;
      right: 0%;
      top: 7%;
      img {
        width: 100%;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

// 能力展示
.similar-main-wrap {
  padding: 80px 0;
  width: 100%;
  background: #f0f2f5;
  /deep/ .common-text {
    img {
      vertical-align: middle;
      display: inline-block;
      margin: -2px 3px 0;
      width: 24px;
    }
  }
  .operationBackgroundColor {
    height: calc(100% - 62px);
  }
  .similarityGraph {
    width: 32.8%;
    background: #d8d8d8;
    border-radius: 8px;
  }
  .operationRight {
    height: 100%;
    margin-left: 16px;
  }
  .similarityGraphMain {
    height: 100%;
    justify-content: space-between;
    row-gap: 0.8%;
    column-gap: 0.8%;
  }
  .similarityGraphImg img {
    border-radius: 8px;
    overflow: hidden;
  }
}
.similar-main-cont {
  // display: flex;
  width: 100%;
  height: 770px;
  margin: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  box-sizing: border-box;
}

/* 差异优势 */
.different-advantage-wrap {
  background: linear-gradient(140deg, #503ad9 20%, #722ab8 145%);
}
.different-advantage {
  padding-top: 80px;
  padding-bottom: 75px;
}
.different-advantage /deep/ .common-two-column {
  column-gap: 109px;
}
.different-advantage /deep/ .common-two-column_img {
  width: 40.5%;
  margin-right: 9px;
  margin-top: -80px;
}

/* 应用场景 */
.application-scene-wrap {
  width: 100%;
  background-image: url(../../assets/img/imgSimilar/app-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.application-scene {
  padding-top: 80px;
  padding-bottom: 189px;
}

.application-scene_main {
  margin-top: 209px;
  .app-title {
    background: #6c38e0;
    border: 1px solid #6c38e0;
    font-size: 24px;
    padding: 8px 8px 8px 48px;
    color: #fff;
    border-radius: 157px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 64px;
      background: #ff8645;
      border-radius: 100px;
    }
  }
  .app-list {
    margin-top: 36px;
    padding-left: 49px;
    row-gap: 24px;
    li {
      display: flex;
      align-items: flex-start;
      color: #5d5f6c;
      font-size: 16px;
    }
    img {
      width: 22px;
      margin-right: 8px;
    }
  }
}
.application-scene /deep/ .common-two-column {
  column-gap: 85px;
}
.application-scene /deep/ .common-two-column_img {
  width: 30.9%;
  margin-left: 58px;
  margin-top: -132px;
}

.core-function_main,
.different-advantage_main {
  margin-top: 80px;
}

@media screen and (max-width: 1280px) {
  .header-custom .bg-left,
  .header-custom .bg-right {
    width: 47.5%;
  }
  // 能力展示
  .similar-main-cont {
    height: fit-content;
    .similarityGraphMain {
      justify-content: flex-start;
    }
    .similarityGraph {
      padding-bottom: 25px;
      height: auto;
      img {
        max-width: 90%;
        max-height: 90%;
      }
    }
    .operationRight {
      margin-left: 0;
    }
  }
  .operationBackgroundColor {
    height: auto;
    flex-direction: column;
  }

  .application-scene {
    .application-scene_main {
      margin-top: 140px;
    }
    /deep/.common-two-column_img {
      margin-top: 0;
    }
    .app-title {
      font-size: 18px;
      img {
        width: 37px;
      }
    }
    .app-list li {
      font-size: 12px;
      img {
        width: 16px;
      }
    }
    .common-text {
      img {
        width: 14px;
      }
    }
  }
}
</style>
